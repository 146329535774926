import './css/Nav.css';

function Nav() {
	return (
		<div style={{ paddingTop: "50px" }} id='home'>
			<nav className="nav">
				<label htmlFor="toggler" className="nav-head">
					<span className="material-symbols-outlined" id="menu-icon">
						menu
					</span>
				</label>
				<input className="navbar-toggler" type="checkbox" id="toggler" />

				<div className="navbar">
					<ul>
						<li>
							<a className="glassmorphism" href="#home">
								Accueil
							</a>
						</li>
						<li>
							<a className="glassmorphism" href="#event">
								Types d’événements
							</a>
						</li>
						<li>
							<a className="glassmorphism" href="#borne">
								Borne à photos
							</a>
						</li>
						<li>
							<a className="glassmorphism" href="#tarif">
								Tarifs / Cadres
							</a>
						</li>
						<li>
							<a className="glassmorphism" href="#kits">
								Nos kits
							</a>
						</li>
						<li>
							<a className="glassmorphism" href="#form">
								Contact
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
  	);
}

export default Nav;
