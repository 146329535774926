import React from 'react';
import confetti from 'canvas-confetti';
import './css/Header.css';

function Header() {
	const icon = process.env.PUBLIC_URL + '/favicon.webp';

	const handleClick = () => {
		confetti({
			particleCount: 150,
			spread: 100,
			origin: { y: 0.6 }
		});
	};

	return (
		<div className="head">
			<section className="glassmorphism header">
				<div className="title-wrapper">
					<h1 className="sweet-title">
						<img src={icon} style={{ height: "250px" }} alt="Funny Selfie" onClick={handleClick} />
					</h1>
					<span className="top-title">Vos souvenirs sont ici !</span>
					<span className="bottom-title">Cheeese, c’est dans la boîte !</span>
				</div>
			</section>
		</div>
	);
}

export default Header;
