import React from 'react';
import './css/Story.css';

function StorySection() {
  return (
    <div className="story-section">
      <h2>Funny Selfie c'est répondre au besoin de l'imortalisation du moment</h2>
      <p>
        Que ce soit pour un anniversaire mémorable, un mariage de rêve, un baptême tout
        en douceur, une soirée d’entreprise, le lancement d’un produit, un salon
        professionnel ou un séminaire, notre borne est la cerise Selfie sur le gâteau de
        chaque événement. Imaginez-vous et vos proches se déchaîner devant la caméra,
        créant des souvenirs éblouissants avec des poses décalées et des sourires
        contagieux.
        <br /><br />
        Les anniversaires deviennent encore plus festifs avec nos selfies délirants,
        immortalisant chaque moment de joie et d’amusement. Au mariage, chaque cliché
        raconte une histoire d’amour unique, ajoutant une touche de spontanéité à la journée
        spéciale. Pour les baptêmes, les souvenirs précieux sont capturés avec une touche
        de fantaisie, créant des liens inoubliables entre famille et amis. Pour les soirées
        d’entreprise et autres événements professionnels, vos équipes et clients garderont
        un souvenir mémorable de ce sympathique moment.
      </p>
    </div>
  );
}

export default StorySection;
