import React, { useState } from 'react';
import './css/Footer.css';

const Footer = () => {
    const [showConditions, setShowConditions] = useState(false);

    const toggleConditions = () => {
        setShowConditions(!showConditions);
    };

    return (
        <div className="interstitial-section">
            <h2>Informations diverses :</h2>
            <div className='button-container'>
                <a rel='nofollow' href='https://www.mariages.net' title='Mariages.net' style={{textDecoration: 'none', color: 'inherit'}}>
                    <img alt='Mariages.net' src='https://www.mariages.net/images/sellos/label-partenaire--gg335374.png' style={{borderWidth: '0px'}} />
                </a>
                <a href='https://baptiste-avert.fr' className='button' style={{width: '250px'}}>
                    Site du développeur
                </a>
                <button onClick={toggleConditions} style={{width: '250px'}}>
                    {showConditions ? 'Cacher' : 'Montrer'} les conditions d'utilisation du site
                </button>
            </div>
            {showConditions && (
            <p style={{textAlign: "left"}}>
                <strong>Conditions d'Utilisation</strong>
                <br/><br/>
                Bienvenue sur notre site web. En accédant à ce site, vous acceptez de vous conformer à ces conditions d'utilisation, à toutes les lois et réglementations applicables, et convenez que vous êtes responsable du respect des lois locales applicables. Si vous n'êtes pas d'accord avec l'une de ces conditions, il vous est interdit d'utiliser ou d'accéder à ce site. Les matériaux contenus dans ce site web sont protégés par le droit d'auteur et le droit des marques.
                <br/><br/>
                <strong>Utilisation des Logiciels et Librairies</strong>
                <br/><br/>
                Les logiciels et librairies utilisés sur ce site, y compris React, NodeMailer, Express, qui sont utilisés conformément aux licences sous lesquelles ils ont été publiés. Ces logiciels sont fournis "tels quels", sans garantie d'aucune sorte, expresse ou implicite.
                <br/><br/>
                <strong>Collecte et Utilisation des Données Personnelles</strong>
                <br/><br/>
                En soumettant un formulaire sur notre site, vous consentez à ce que nous collections et utilisions vos données personnelles dans le but de vous recontacter. Nous nous engageons à respecter la confidentialité de vos données personnelles et à les traiter conformément au Règlement Général sur la Protection des Données (RGPD) et à la législation française en vigueur.
                <br/><br/>
                Nous ne partagerons, ne vendrons, ni ne louerons vos données personnelles à des tiers, sauf si requis par la loi ou si nécessaire pour fournir le service demandé.
                <br/><br/>
                <strong>Limitations</strong>
                <br/><br/>
                En aucun cas, nous ne pourrons être tenus responsables de tout dommage (y compris, sans limitation, les dommages pour perte de données ou profit, ou en raison d'une interruption d'activité) résultant de l'utilisation ou de l'incapacité d'utiliser les matériaux sur notre site Internet, même si nous ou un représentant autorisé a été informé oralement ou par écrit de la possibilité de tels dommages.
                <br/><br/>
                <strong>Modifications des Conditions d'Utilisation</strong>
                <br/><br/>
                Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment sans préavis. En utilisant ce site web, vous acceptez d'être lié par la version actuelle de ces conditions d'utilisation.
                <br/><br/>
                <strong>Droit Applicable</strong>
                <br/><br/>
                Toute réclamation relative à notre site web sera régie par les lois de l'État français sans égard à ses conflits de dispositions légales.
            </p>
            )}
        </div>
    );
};

export default Footer;
