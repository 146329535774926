import './css/App.css';
import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Nav from './Nav';
import Header from './Header';
import Pricing from './Pricing';
import Story from './Story';
import InterSection from './InterSection';
import Event from './Event';
import Form from './Form';
import ImageGrid from './Cadre';
import ProductsGrid from './ProductsGrid';
import BornePhoto  from './BornePhoto';
import Footer  from './Footer';

function App() {
	useEffect(() => {
		const isFirstVisit = localStorage.getItem('isFirstVisit');

		if (!isFirstVisit) {
			confetti({
				particleCount: 150,
				spread: 100,
				origin: { y: 0.6 }
			});

		localStorage.setItem('isFirstVisit', 'true');
		}
	}, []);

	return (
		<div>
		<Nav />
		<Header />
		<Story />
		<InterSection />
		<Event />
		<BornePhoto />
		<Pricing />
		<ProductsGrid />
		<ImageGrid />
		<Form />
		<Footer />
		</div>
	);
}

export default App;
