import React from 'react';
import './css/Pricing.css';
import { Image } from 'antd';

import Article1 from './img/Article1.webp';
import Article2 from './img/Article2.webp';
import Article3 from './img/Article3.webp';
import Article4 from './img/Article4.webp';
import Article5 from './img/Article5.webp';

const Card = ({ title, prices, features, image }) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      <div className="card-image-container">
        <Image loading="lazy" width={"230px"} src={image} alt={features}/>
      </div>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <ul style={{textAlign: "center", fontWeight: "800", fontSize: "20px"}}>
        {prices.map((price, index) => (
          <li key={index}>{price}</li>
        ))}
      </ul>
    </div>
  );
};

const PricingSection = () => {
  const cardInfo = [
    {
        title: "Kit bulles",
        prices: ["15 €"],
        features: ["Kit bulles pour messages (10 pièces)"],
        image: [Article1],
    },
    {
        title: "Ensemble de ballons",
        prices: ["15 €"],
        features: ["Ensemble de ballons (16 pièces), avec différents coloris disponibles"],
        image: [Article2],
    },
    {
        title: "Cadre gonflable",
        prices: ["20 €"],
        features: ["Cadre gonflable, avec différents coloris disponibles"],
        image: [Article3],
    },
    {
        title: "Kit d'accessoires",
        prices: ["20 €"],
        features: ["Kit d'accessoires, avec différents coloris disponibles"],
        image: [Article4],
    },
    {
        title: "Ensemble de ballons",
        prices: ["30 €"],
        features: ["Ensemble de ballons (150 à 180 pièces), avec différents coloris disponibles"],
        image: [Article5],
    }
  ];

  return (
    <section className="pricing-section" id='kits'>
      <h2>Nos Kits</h2>
      <div className="cards-container">
        {cardInfo.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </section>
  );
};

export default PricingSection;
