import React, { useState } from 'react';
import './css/Event.css';

import Event1 from './img/Event1.webp';
import Event2 from './img/Event2.webp';
import Event3 from './img/Event3.webp';

const Event = () => {
  const [selectedEvent, setSelectedEvent] = useState('anniversaire');

  return (
    <div className="event" id='event'>
      <h2>Les événements</h2>
      <div className="image-grid">
        <div
          className={`image-item ${selectedEvent === 'anniversaire' ? 'selected' : ''}`}
          onClick={() => setSelectedEvent('anniversaire')}
        >
          <img loading="lazy" src={Event1} height={"200px"} alt="Anniversaire" className="image" />
          <div className="title">Anniversaire</div>
        </div>
        <div
          className={`image-item ${selectedEvent === 'mariage' ? 'selected' : ''}`}
          onClick={() => setSelectedEvent('mariage')}
        >
          <img loading="lazy" src={Event2} height={"200px"} alt="Mariage" className="image" />
          <div className="title">Mariage</div>
        </div>
        <div
          className={`image-item ${selectedEvent === 'entreprise' ? 'selected' : ''}`}
          onClick={() => setSelectedEvent('entreprise')}
        >
          <img loading="lazy" src={Event3} height={"200px"} alt="Entreprise" className="image" />
          <div className="title">Entreprise</div>
        </div>
      </div>
      <div className="event-details">
        {selectedEvent === 'anniversaire' && (
          <>
            <h3>Anniversaire, Baptême, Communion</h3>
            <p>
                Afin de fêter cette événement avec vos proches, immortalisez ce moment festif avec
                notre Borne à selfie pour des instants insolites. De nombreuses personnalisations
                sont possibles :
                <br/><br/>
                Plusieurs formats d’impression de vos photos (15x20, 10x15, 5x15), personnalisation
                totale des clichés, envoi par mail des photos ou par un QR code, animations gif, de
                nombreuses possibilités s’offres à vous…
                <br/><br/>
                Non seulement notre Borne à Selfie ajoute une dose de plaisir, mais elle crée aussi
                une connexion instantanée. Les participants se réunissent pour des clichés
                mémorables, échangeant des fous rires et partageant des moments authentiques.
                <br/>
                De plus, chaque photo personnalisée devient un souvenir tangible qui rappelle la joie
                partagée.
                <br/><br/>
                Bref vous aurez toutes les options possibles pour faire de votre anniversaire un
                moment inoubliable pour vous et vos invités.
            </p>
          </>
        )}
        {selectedEvent === 'mariage' && (
          <>
            <h3>Mariage</h3>
            <p>
                Votre mariage, ce grand jour, le jour de votre vie, le jour dont vous vous souviendrez
                toute votre vie… vous l’aviez imaginé parfait, et vous aviez raison, il le sera ! Voici
                pourquoi il est si important de trouver les meilleures animations : celui qui fera de ce
                grand jour un moment unique pour vous et vos invités.
                <br/><br/>
                Notre Borne à selfie avec ses nombreuses personnalisations :
                <br/><br/>
                Plusieurs formats d’impression de vos photos (15x20, 10x15, 5x15), personnalisation
                totale des clichés, envoi par mail des photos ou par un QR code, animations gif, de
                nombreuses possibilités s’offres à vous…
                <br/>
                Non seulement notre « Borne à Selfie » ajoute une dose de plaisir, mais elle crée
                aussi une connexion instantanée. Les participants se réunissent pour des clichés
                mémorables, échangeant des fous rires et partageant des moments authentiques.
                <br/>
                De plus, chaque photo personnalisée devient un souvenir tangible qui rappelle la joie
                partagée.
                <br/><br/>
                Bref vous aurez toutes les options possibles pour faire de votre fête un moment
                inoubliable pour vous et vos invités.
            </p>
          </>
        )}
        {selectedEvent === 'entreprise' && (
          <>
            <h3>Entreprise</h3>
            <p>
                Que vous organisiez une soirée d’entreprise survoltée, un lancement de produit qui
                fera sensation ou un stand époustouflant lors d’un salon professionnel, notre borne
                ajoute une dose irrésistible de glamour et de convivialité. Imaginez vos collègues et
                partenaires se rassembler pour des selfies créatifs, chaque clic devenant une
                opportunité de réseautage amusante.
                <br/><br/>
                Lors des soirées d’entreprise, notre borne est le catalyseur parfait pour briser la
                glace et créer des liens authentiques entre collègues. Pour les lancements de
                produits, les selfies deviennent des ambassadeurs visuels, transmettant l’excitation
                de la nouveauté avec chaque instant capturé.
                <br/><br/>
                Aux salons professionnels, notre Borne Selfie est un aimant qui attire les visiteurs
                et crée une expérience inoubliable au sein de votre stand.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Event;
