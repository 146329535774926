import React from 'react';
import './css/Cadre.css';
import { Image } from 'antd';

import Cadre1 from './img/Cadre1.webp';
import Cadre2 from './img/Cadre2.webp';
import Cadre3 from './img/Cadre3.webp';
import Cadre4 from './img/Cadre4.webp';

function ImageGrid() {
    return (
        <div className="cadre-section">
            <h2>Nos Cadres</h2>
            <p>Ceci sont des exemples de cadres, un cadre est fait pour chaques demande de locations de bornes</p>
            <div className="cadre-grid">
                <div className="cadre-item"><Image loading="lazy" width={"350px"} src={Cadre1} alt="Cadre joyeux anniversaire Alexandre 18 ans !" /></div>
                <div className="cadre-item"><Image loading="lazy" width={"350px"} src={Cadre2} alt="Cadre Merry Chrismas !" /></div>
                <div className="cadre-item"><Image loading="lazy" width={"350px"} src={Cadre3} alt="Cadre joyeux anniversaire Louise 1 an !" /></div>
                <div className="cadre-item"><Image loading="lazy" width={"350px"} src={Cadre4} alt="Cadre Mariage, Anne et Stéphane, Ils se sont dit oui" /></div>
            </div>
        </div>
    );
}

export default ImageGrid;