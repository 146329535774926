import React from 'react';
import './css/InterSection.css';

function InterstitialSection() {
    return (
        <div className="interstitial-section">
            <h2>Vos fêtes seront réussies, des événements clés en mains !</h2>
            <h2>Créez l’événement avec les animations !</h2>
            <h2>Une prestation adaptée à tous types d’événements…</h2>
        </div>
    );
}

export default InterstitialSection;
