import React from 'react';
import './css/Pricing.css';

const Card = ({ title, prices, features }) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      <ul>
        {prices.map((price, index) => (
          <li key={index}>{price}</li>
        ))}
      </ul>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

const PricingSection = () => {
  const cardInfo = [
    {
      title: "100 Impressions",
      prices: ["199 €"],
      features: ["100 impressions photos incluses",
                "La location de la borne",
                "Photos illimitées en digital",
                "Envoie par QR code des photos à chaque personne",
                "Envoie par email",
                "Imprimante professionnelle à haute vitesse d'impression (impression en 8 secondes)",
                "Retrait de la borne dans nos locaux",
                "Cadre personnalisé sur mesure",
                "Envoie numérique des photos après événement."],
    },
    {
        title: "200 Impressions",
        prices: ["249 €"],
        features: ["200 impressions photos incluses",
                "La location de la borne",
                "Photos illimitées en digital",
                "Envoie par QR code des photos à chaque personne",
                "Envoie par email",
                "Imprimante professionnelle à haute vitesse d'impression (impression en 8 secondes)",
                "Retrait de la borne dans nos locaux",
                "Cadre personnalisé sur mesure",
                "Envoie numérique des photos après événement."],
    },
    {
        title: "300 Impressions",
        prices: ["299 €"],
        features: ["300 impressions photos incluses",
                "La location de la borne",
                "Photos illimitées en digital",
                "Envoie par QR code des photos à chaque personne",
                "Envoie par email",
                "Imprimante professionnelle à haute vitesse d'impression (impression en 8 secondes)",
                "Retrait de la borne dans nos locaux",
                "Cadre personnalisé sur mesure",
                "Envoie numérique des photos après événement."],
    },
    {
        title: "400 Impressions",
        prices: ["349 €"],
        features: ["400 impressions photos incluses",
                "La location de la borne",
                "Photos illimitées en digital",
                "Envoie par QR code des photos à chaque personne",
                "Envoie par email",
                "Imprimante professionnelle à haute vitesse d'impression (impression en 8 secondes)",
                "Retrait de la borne dans nos locaux",
                "Cadre personnalisé sur mesure",
                "Envoie numérique des photos après événement."],
    },
    {
        title: "Forfait Digital, (sans impression)",
        prices: ["179 €"],
        features: ["La location de la borne",
                  "Photos illimitées en digital",
                  "Envoie par QR code des photos à chaque personne",
                  "Envoie par email",
                  "Retrait de la borne dans nos locaux",
                  "Envoie numérique des photos après événement."],
    }
  ];

  return (
    <section className="pricing-section" id='tarif'>
      <h2>Tarifs</h2>
      <div className="cards-container">
        {cardInfo.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
      <h4>Autres Forfaits possibles et sur mesure, envoie d’un devis.</h4>
    </section>
  );
};

export default PricingSection;
