import React from 'react';
import { Image } from 'antd';
import './css/BornePhoto.css';

import Borne1 from './img/Borne1.webp';
import Borne2 from './img/Borne2.webp';
import Borne3 from './img/Borne3.webp';
import Borne4 from './img/Borne4.webp';
import Borne5 from './img/Borne5.webp';
import Borne6 from './img/Borne6.webp';
import Borne7 from './img/Borne7.webp';
import Video1 from './img/Video1.mp4';

const BornePhoto = () => {
    const features = [
        "Possibilité de choisir de 100 à 400 tirages photos personnalisées au format 10x15 cm (ou 800 au format bandelette), ou bien format 15x20. Possibilité de ne pas imprimer une photo (mais chut, elle est quand même sauvegardée).",
        "Éclairage LED",
        "Appareil photo reflex",
        "Grand écran tactile 23'",
        "Imprimante photo thermique professionnelle",
        "Prise de photos illimitée en format numérique.",
        "Retrait de la Funny Selfie dans nos locaux (vous ou l'un de vos proches venez récupérer la Borne. Nous prenons 15/20 minutes pour bien vous montrez son fonctionnement. La Borne à Selfie se sépare en trois blocs, très facilement transportable à l'arrière d'une voiture citadine.",
        "Livraison de Funny Selfie possible",
        "Utilisation en position assise ou debout, en intérieur comme en extérieur, de jour comme de nuit.",
        "Récupération de toutes vos photos en format numérique (avec et sans la mise en page). Téléchargement gratuit pour vous et vos invités sur votre galerie privée.",
        "Simple d'utilisation",
        "Photo / GIF / Boomerang / Vidéo",
        "Impressions instantanées",
        "Personnalisation des tirages",
        "Partage par e-mail (sous réserve d'une connexion)",
        "Assistance téléphonique 7 jours / 7 (9h à 22h).",
        "Montage simple et rapide en moins de 10 minutes",
        "Position basse (face à un canapé) ou debout",
        "Éclairage LED",
        "Appareil photo reflex",
        "Grand écran tactile 23'",
        "Imprimante photo thermique professionnelle"
    ];

    const images = [
        Borne5,
        Borne6,
        Borne1,
        Borne2,
        Borne4,
        Borne7,
        Borne3,
    ];

    return (
        <div className="cadre-section"  id='borne'>
            <h2>Notre Borne à photos</h2>
            <div className='card' style={{maxWidth: "none", margin: "0", width: "100%"}}>
                <ul style={{padding: "10px", lineHeight: "30px"}}>
                    {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>

            <h2 style={{marginTop: "24px"}}>Images de notre borne à photos</h2>
            <div className="cadre-grid">
                {images.map((src, index) => (
                    <div key={index} className="cadre-item">
                        <Image loading="lazy" width={"250px"} style={{justifyItems: "center"}} src={src} alt='Image de la borne à selfie'/>
                    </div>
                ))}
            </div>

            <h2 style={{ marginTop: "24px" }}>Vidéo de démonstration</h2>
            <div className="cadre-grid video-card">
                <video controls>
                    <source src={Video1} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default BornePhoto;
