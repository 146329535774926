import './css/Form.css';
import confetti from 'canvas-confetti';
import React, { useState } from 'react';

const GlassForm = () => {
    const [isProfessional, setIsProfessional] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleTypeChange = (event) => {
        setIsProfessional(event.target.value === 'professionnel');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const formObject = Object.fromEntries(formData.entries());

        try {
            const response = await fetch('https://funnyselfie.fr:3001/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formObject),
            });

            if (response.ok) {
                setShowConfirmation(true);
                alert("Votre formulaire a été envoyé avec succès.");
            } else {
                console.error('Form submission failed');
            }

			confetti({
				particleCount: 150,
				spread: 100,
				origin: { y: 0.6 }
			});
        } catch (error) {
            console.error('There was an error submitting the form', error);
        }
    };

	return (
		<div className="glass-form-container" id='form'>
			{showConfirmation && <div className="confirmation-message">Votre formulaire a été envoyé avec succès.</div>}
			<h2>Formulaire de prise de contact / devis</h2>
			<p>
				Prenez contact avec nous si vous avez la moindre question concernant notre Funny Selfie (Borne à photos), et si vous souhaitez plus d’informations.<br/><br/>
				Notre équipe est à votre disposition au <span>06 98 56 26 72</span> pour répondre à vos questions sous 24H.<br/><br/>
				Vous pouvez également nous contacter à travers le formulaire de contact ci-dessous.<br/><br/>
				Nous reviendrons vers vous dans les plus brefs délais.
			</p>
			<div className="glass-form">
				<form onSubmit={handleSubmit}>
					<div className="input-group">
						<p>Vous êtes : *</p>
						<label>
							<input type="radio" name="type" value="particulier" required onChange={handleTypeChange} /> Particulier
						</label>
						<label>
							<input type="radio" name="type" value="professionnel" onChange={handleTypeChange} /> Professionnel
						</label>
					</div>

					{isProfessional && (
						<div className="input-group">
							<label htmlFor="societe">Société *</label>
							<input type="text" id="societe" name="societe" required />
						</div>
					)}

					<div className="input-group">
						<label htmlFor="nom">Nom *</label>
						<input type="text" id="nom" name="nom" required />
					</div>

					<div className="input-group">
						<label htmlFor="email">E-mail *</label>
						<input type="email" id="email" name="email" required />
					</div>

					<div className="input-group">
						<label htmlFor="telephone">Téléphone *</label>
						<input type="tel" id="telephone" name="telephone" required />
					</div>

					<div className="input-group">
						<label htmlFor="nombre">Nombre de personnes *</label>
						<input type="number" id="nombre" name="nombre" min="0" required />
					</div>

					<div className="input-group">
						<label htmlFor="date">Date de l'événement *</label>
						<input type="date" id="date" name="date" required />
					</div>

					<div className="input-group">
						<label htmlFor="ville">Ville de l'événement *</label>
						<input type="text" id="ville" name="ville" required />
					</div>

					<div className="input-group">
						<label htmlFor="details">Dites nous en plus sur l’événement</label>
						<textarea id="details" name="details"></textarea>
					</div>
					{!showConfirmation && (
						<button type="submit">Envoyer</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default GlassForm;
